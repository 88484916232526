window.addEventListener('load', function () {
  // bodyにヘッダー(+ソートメニュー)分の高さのpadding-topを適用する
  function adjustPadding() {
    // bodyにヘッダー(+ソートメニュー)分の高さのpadding-topを適用する
    if (document.querySelector('.modAgeSortWrapper')) {
      const hh = document.querySelector('.gbHeader') ? document.querySelector('.gbHeader').offsetHeight : 0;
      document.querySelector('.modAgeSortWrapper').style.top = hh + 'px';
    }
  }
  //リサイズ時にも上記処理を実行
  window.addEventListener('load', adjustPadding);
  window.addEventListener('resize', adjustPadding);

  // メニューを閉じる関数
  const closeMenu = function () {
    const wrapper = document.querySelector('.gbDrawerNaviWrapper');
    const jsFirstModalBack = document.querySelector('.js-firstModalBack');

    if (wrapper && jsFirstModalBack) {
      wrapper.classList.remove('isOpen');
      jsFirstModalBack.classList.remove('isShow');
    }
  };

  // メニューを開く& 閉じる
  document.addEventListener('click', function (event) {
    // メニューを開く
    if (event.target.closest('.js-menuOpen')) {
      const gbDrawerNaviWrapper = document.querySelector('.gbDrawerNaviWrapper');
      const firstModalBack = document.querySelector('.js-firstModalBack');

      if (gbDrawerNaviWrapper && firstModalBack) {
        //メニューを開く
        if (gbDrawerNaviWrapper.classList.contains('isOpen')) {
          gbDrawerNaviWrapper.classList.remove('isOpen');
          firstModalBack.classList.remove('isShow');
        }
        //メニューを閉じる
        else {
          gbDrawerNaviWrapper.classList.add('isOpen');
          firstModalBack.classList.add('isShow');
        }
      }
    }

    // メニューを閉じる
    else if (event.target.closest('.js-menuClose') || event.target.closest('.js-firstModalBack')) {
      closeMenu();
    }
  });

});

document.addEventListener('DOMContentLoaded', function () {
  //年齢・月齢ソートのtopの値を定義
  function adjustModAgeSortWrapper() {
    const header = document.querySelector('.gbHeader');
    const modAgeSortWrapper = document.querySelector('.modAgeSortWrapper');

    if ( ! header || ! modAgeSortWrapper) {
      return;
    }

    modAgeSortWrapper.style.top = header.offsetHeight + 'px';
  }

  window.addEventListener('load', adjustModAgeSortWrapper);
  window.addEventListener('resize', adjustModAgeSortWrapper);
});

//スムーススクロール
// href属性の「#」で始まるリンクを全て取得
const links = document.querySelectorAll('a[href^="#"]');

// 取得したリンクを1つずつ処理を実行する
links.forEach((link) => {
  // リンクをクリックしたら処理を実行する
  link.addEventListener('click', (e) => {
    // リンクイベントをキャンセルする
    e.preventDefault();
    // クリックしたリンクのhref属性を取得
    const href = link.getAttribute('href');
    // 目的のセクションを取得
    const targetSection = href === '#' ? document.body : document.querySelector(href);
    // 画面の上からセクションのtop位置までの垂直方向の距離
    const sectionTop = targetSection.getBoundingClientRect().top;
    // 現在位置を取得
    const currentPos = window.scrollY;

    // ヘッダーの高さを動的に取得
    const headerHeight = document.querySelector('.gbHeader').offsetHeight;

    // .modAgeSortWrapperが存在するかをチェックして高さを取得
    const modAgeSortWrapper = document.querySelector('.modAgeSortWrapper');
    const modAgeSortWrapperHeight = modAgeSortWrapper ? modAgeSortWrapper.offsetHeight : 0;

    // gapを計算
    const gap = headerHeight + modAgeSortWrapperHeight + 16; // 16pxを追加

    // 現在位置から目的のsectionまでのスクロール量
    const target = sectionTop + currentPos - gap;

    // 特定の位置までスクロールさせる
    window.scrollTo({
      top: target, // 目的の位置のY座標を指定
      behavior: 'smooth', // スクロールの動きを指定
    });
  });
});

//別ページからアンカーリンクにアクセスした時の表示位置調整
document.addEventListener("DOMContentLoaded", function() {
  function adjustAnchor() {
      const header = document.querySelector(".gbHeader");
      const sortWrapper = document.querySelector(".modAgeSortWrapper");

      let offset = 0;

      if (header) {
          offset += header.offsetHeight;
      }
      
      if (sortWrapper) {
          offset += sortWrapper.offsetHeight;
      }

      // Get the current hash without the '#' character
      const hash = window.location.hash.substring(1);

      // If there's a hash in the URL
      if (hash) {
          const element = document.getElementById(hash);

          if (element) {
              window.scrollTo({
                  top: element.offsetTop - offset,
                  behavior: "smooth"
              });
          }
      }
  }

  // Adjust the anchor when the page loads
  adjustAnchor();

  // Adjust the anchor when a hash change is detected (e.g., user clicks on an anchor link)
  window.addEventListener("hashchange", adjustAnchor);
});

//保存や共感などのチップの動作
document.addEventListener('DOMContentLoaded', function() {
  var touchElements = document.querySelectorAll('.pChip');  // クラス .pChip を持つ全ての要素を取得
  touchElements.forEach(function(touchElement) {
      touchElement.addEventListener('click', function() {
          touchElement.classList.add('isActive');  // クリックされた要素に .isActive クラスを追加
          setTimeout(function() {
              touchElement.classList.remove('isActive');  // クリックされた要素から .isActive クラスを削除
          }, 20);
      });
  });
});

//セレクトボックスっぽいパーツの動作
document.addEventListener('DOMContentLoaded', () => {
  const triggers = document.querySelectorAll('.modSelectTrigger');
  let mdLayerSeccond = document.querySelector('.mdLayerSeccond');

  function handleResize() {
      mdLayerSeccond = window.matchMedia('(max-width: 1025px)').matches ? document.querySelector('.mdLayerSeccond') : null;
  }

  handleResize();
  window.addEventListener('resize', handleResize);

  triggers.forEach(trigger => {
      const button = trigger.querySelector('button');
      const listContainer = trigger.querySelector('.modSelectListContainer');
      const listItems = trigger.querySelectorAll('.modSelectList li');

      button.addEventListener('click', (e) => {
          e.stopPropagation();
          toggleShow(trigger);
      });

      document.addEventListener('click', (e) => {
          if (!trigger.contains(e.target)) {
              removeShow(trigger);
          }
      });

      listItems.forEach(item => {
          item.addEventListener('click', () => {
              removeShow(trigger);
          });
      });
  });

  function toggleShow(trigger) {
      trigger.classList.toggle('isShow');
      if (mdLayerSeccond) {
          mdLayerSeccond.classList.toggle('isShow');
      }
  }

  function removeShow(trigger) {
      trigger.classList.remove('isShow');
      if (mdLayerSeccond) {
          mdLayerSeccond.classList.remove('isShow');
      }
  }
});

//テキストエリアを入力しながら自動で伸ばす
document.addEventListener("DOMContentLoaded", function() {
  const textareas = document.querySelectorAll(".js-flexibleTextArea");

  textareas.forEach(textarea => {
      // 初期化時に高さを調整
      adjustHeight(textarea);

      textarea.addEventListener("input", function() {
          adjustHeight(textarea);
      });
  });

  function adjustHeight(textarea) {
      textarea.style.height = 'auto'; // 高さをリセット
      textarea.style.height = textarea.scrollHeight + 'px'; // 新しい高さを設定
  }
});

// メインカラム・サブカラムのスクロール固定
window.addEventListener('load', function() {
  // 現在のスクロール位置によってメインカラムの固定を調整
  const adjustColumns = function(event) {
    // window.innerWidth が 835px 以下の場合は何もしない
    if (window.innerWidth <= 835) {
      return;
    }

    // メインカラム・サイドカラム・ラッパー・ヘッダーの要素を取得
    const mainColumn = document.querySelector('.gbMainContainer');
    const sideColumn = document.querySelector('.gbSideContainer');
    const wrapper = document.querySelector('.gbWrapper');
    const header = document.querySelector('.gbHeader');

    // どれか一つでも欠けていたら何もしない
    if ( ! mainColumn || ! sideColumn || ! wrapper || ! header) {
      return;
    }

    // メインカラム・サイドカラム・高さ、ラッパーの Y 座標、ヘッダーの高さを取得
    const mainColumnHeight = mainColumn.offsetHeight;
    const sideColumnHeight = sideColumn.offsetHeight;
    const wrapperY = wrapper.getBoundingClientRect().y + window.scrollY;
    const headerHeight = header.getBoundingClientRect().height;

    // サイドカラムよりメインカラムの方が高い場合は何もしない
    if (mainColumnHeight >= sideColumnHeight) {
      mainColumn.style.position = 'static';
      return;
    }

    // メインカラムの下端が画面下端に達している
    if (window.scrollY > wrapperY + mainColumnHeight - window.innerHeight + 30) {
      // かつ、サイドカラムの下端が画面下端に達していない場合は固定
      if (window.scrollY < wrapperY + sideColumnHeight - window.innerHeight + 30) {
        wrapper.style.justifyContent = 'flex-end';
        mainColumn.style.position = 'fixed';
        mainColumn.style.left = '0';

        // メインカラムが画面より高い場合は下端を固定
        if (mainColumnHeight > window.innerHeight) {
          mainColumn.style.bottom = '0';
        }

        // メインカラムが画面より小さい場合は上端を固定
        else {
          mainColumn.style.top = headerHeight+'px';
        }
      }

      // かつ、サイドカラムの下端が画面下端に達している場合は固定解除
      else {
        wrapper.style.justifyContent = 'flex-end';
        mainColumn.style.position = 'absolute';
        mainColumn.style.left = '0';

        // メインカラムが画面より高い場合は下端を固定
        if (mainColumnHeight > window.innerHeight) {
          mainColumn.style.bottom = '0';
        }

        // メインカラムが画面より小さい場合は上端を固定
        else {
          mainColumn.style.top = (wrapperY + sideColumnHeight - window.innerHeight + 30)+'px';
        }
      }
    }

    // それ以外の場合は固定解除
    else {
      wrapper.style.justifyContent = '';
      mainColumn.style.position = '';
    }
  };

  // スクロール時に実行
  window.addEventListener('scroll', adjustColumns);

  // リサイズ時にも実行
  window.addEventListener('resize', adjustColumns);

  // ロード時にも実行
  setTimeout(adjustColumns, 1000);
});
